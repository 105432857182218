export default {
    portfolioBattle: '投资组合<br>战役',
    startPlaceholder: '输入邮箱地址，开始战斗吧！',
    hi: '嗨',
    portfolioNameInfo: '为您的投资组合命名并开始投资！',
    portfolioName: '投资组合命名',
    continue: '下一步',
    yourBalance: '您的余额',
    invested: '已投资',
    editInfo: '您可以在战斗开始前编辑投资组合',
    selectAssets: '选择资产',
    add: '添加',
    edit: '编辑',
    done: '完成',
    emailError: '错误的邮箱地址',
    tooLongName: '命名太长了',
    publish: '发布',
    portfolioExist: '此投资组合名称已存在，请更改名称并重试',
    portfolioCreated: '您的投资组合已成功发布！在战斗开始之前，你都可以改变它',
    emailInfo: '快完成了！你需要确认你的电子邮件才能进入战斗。我们已经发送了一封电子邮件，检查它并单击链接。 （如果没有找到，请检查垃圾邮件文件夹）',
    retryAfter: '在 {count} 秒后重试',
    coinSearch: '代币搜索',
    update: '更新',
    moniDescription: '是跟踪您的加密投资组合和探索市场的最简单方法',
    getin: '登陆',
    getinText: '我们向您发送了一封确认电子邮件。请立即查看！',
    spaminfo: '如果您没有找到它，请检查您的垃圾邮件文件夹',
    signout: '退出登陆',
    signoutInfo: '您要离开吗？',
    begin: '开始',
    end: '结束',
    prize: '奖励',
    iUnderstood: '非常棒，兄弟！保持低调，但我们为您欢呼。',
    wannaBePartOfNext: '您想参加下一个吗？',
    nextInfo: '在下一个开始时，单击 Enter 开始。战斗开始，我们会通知您。在那里您可以阅读有关参与条件的信息，等等等等',
    archive: '存档',
    archiveCaption: '在这里查看以前的战斗',
    winner: '获胜者',
    dates: '日期',
    all: '所有',
    tookPart: '参加',
    we: '我们是',
    who: '谁',
    more: '更多',
    withLove: '用爱创造',
    inProcess: '处理中',
    liveCaption: '这意味着现在',
    hiChampion: '你好，冠军！',
    email: '电子邮件',
    upcomingBattles: '即将到来的战斗',
    upcomingBattlesCaption: '这意味着非常快',
    registration: '注册',
    start: '开始',
    final: '结束',
    members: '成功',
    takePart: '我们出发吧！',
    join: '加入',
    registrationWillBegin: '注册即将开始',
    battleWillBegin: '战斗即将开始',
    registerWillEnd: '注册即奖结束',
    in: 'IN',
    showMore: '展示更多',
    checkRules: '请查看！',
    terms: '参与条款',
    termsMoreInfo: '再一次提醒，当你赢了（我们相信你！）以后，需要完成所有必要的步骤，才能得到奖品！',
    info404: '回到主页',
    alldone: '已完成',
    maxSumPopup: '您可以以不超过400美元的价格选择一项资产',
    fillPortfolioName: '填写您的投资组合名称',
    battleWillEnded: '战斗即将结束',
    battleEnded: '战斗已结束',
    battleName: '战斗<br>名字',
    becomePartner: '成为Moni的战斗伙伴！',
    becomePartnerShort: '成为合作伙伴',
    becomePartnerLogoInfo: '您的Logo将出现在这里',
    benefits: {
        first: {
            title: '复合促销',
            text: 'Moni的订阅者和我们的合作伙伴将研究您的项目。'
        },
        second: {
            title: '联系方式',
            text: '你可以在战斗中结识有趣和有前途的项目。',
        },
        third: {
            title: '品牌',
            text: '激动人心的发布会!',
        },
        fourth: {
            title: '内容',
            text: '您的项目将为您的观众带来更高层次的乐趣。',
        },
    },
    infoForPartners: '发送一封包含您的项目描述的电子邮件，成为我们的合作伙伴。',
    digestInfo: '从250多个资源中获取最热门🌶的5分钟区块链加密新闻。<span class="font-weight-semiBold">Every&nbsp;day</span>.',
    subscribe: '订阅',
};
